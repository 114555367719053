<template>
  <div v-loading="loading">
    <ui-header :heading="$t('labels.course.participation')"></ui-header>
    <section id="heading" v-if="course">
      <div class="bg-white rounded overflow-hidden">
        <div class="flex-container">
          <div class="flex-column p-4 md:p-6 font-bold flexed">
            <div class="uppercase">{{course.title.filter(t => t.lang === $i18n.locale)[0].value}}</div>
            <rich-text-field 
              v-model="course.description.filter(t => t.lang === $i18n.locale)[0].value" 
              :hideBorder="true"
              :showEditor="false"/>
          </div>
          <!-- <div v-html="course.description.filter(t => t.lang === $i18n.locale)[0].value"></div> -->
          <el-progress class="p-4 md:p-6 flexed" v-if="progressStatus" :stroke-width="24" :text-inside="true" :percentage="progressPercentage" :status="progressStatus"></el-progress>
          <el-progress class="p-4 md:p-6 flexed" v-if="!progressStatus" :stroke-width="24" :text-inside="true" :percentage="progressPercentage" ></el-progress>
        </div>
        <div class="flex-box p-4 md:p-6" v-if="usersNotInCourse">
          <div>
            <ui-button type="green" @click="handleGiveAllCourse" :disabled="usersNotInCourse.length < 1">{{$t('actions.give_all_course')}}</ui-button>
          </div>

          <div v-if="notCompleted.length === 0 && completed.length === 0">{{$t('misc.no-users-in-course')}}</div>

          <div class="flex-box">
            <el-select
              name="assignCourseToUser"
              v-model="selectedUsers"
              multiple
              collapse-tags
              style:="flex-direction: row; min-width: 15rem; max-width: 20rem"
              :disabled="usersNotInCourse.length === 0"
              :placeholder="usersNotInCourse.length > 0 ? `${$t('actions.select')} ${$tc('models.user', 2)}` : `${$t('misc.all-users-have-course')}`">
                <el-option
                  v-for="user in usersNotInCourse"
                  :key="user.userId"
                  :label="`${user.firstName && user.lastName ? user.firstName + ' ' + user.lastName : user.email}`"
                  :value="user.userId">
                </el-option>
              </el-select>
              <div class="ml-4"></div>
              <ui-button type="green" class="ml-4" :disabled="selectedUsers.length < 1" @click="handleSave">{{$t('actions.add')}}</ui-button>
          </div>
        </div>
      </div>
        
    </section>
    
    <section  name="notCompleted" v-if="notCompleted && notCompleted.length > 0" class="mt-4">
      <ui-list
        :heading="$t('misc.not-completed')"
        :headings="[$tc('labels.user.name'), $tc('labels.user.email', 1), $t('labels.course.last-completed'), $t('labels.course.reminder_sendt'), '']"
        :items="notCompleted"
        ref="notCompletedRef"
        :sort="[
          { prop: 'firstName', type: false},
          { prop: 'email', type: false }
        ]"
        :search_selectors="['email', 'firstName', 'lastName']"
      >
          <template v-slot:default="props">
            <ui-list-data 
              :text="`${props.item.firstName ? props.item.firstName : ''} ${props.item.lastName ? props.item.lastName : ''}`"
              :info="''">
            </ui-list-data>

            <ui-list-data
              :text="`${props.item.email}`"
              :info="''">
            </ui-list-data>
            
            <ui-list-data>
            <span v-if="props.item.lastCompleted">{{
                $d(
                    new Date(props.item.lastCompleted),
                    'short',
                    $i18n.locale === 'no' ? 'no' : 'en'
                )
              }}</span>
              <span v-else-if="props.item.completed">{{
                $d(
                    new Date(props.item.completed),
                    'short',
                    $i18n.locale === 'no' ? 'no' : 'en'
                )
              }}</span>
              <span v-else>
                -
              </span>
            </ui-list-data>
            <ui-list-data small :text="`${props.item.reminderSendt ? $d(new Date(props.item.reminderSendt), 'long') : ''}`" />
            <ui-list-data small>
              <ui-link type="success" uppercase size="small" @click="handleReminder(props.item)">{{$t('labels.course.reminder')}}</ui-link>
              <ui-link class="ml-2" type="danger" uppercase size="small" @click="handleOpenDeleteUserCourseModal(props.item)">{{$t('actions.remove')}}</ui-link>
            </ui-list-data>
          </template>
        </ui-list>
    </section>

    <section name="completed" v-if="completed && completed.length > 0" class="mt-4">
      <ui-list
        :heading="$t('misc.completed')"
        :headings="[$tc('labels.user.name'), $tc('labels.user.email', 1), $t('labels.course.last-completed') , '']"
        :items="completed"
        ref="completedRef"
        :sort="[
          { prop: 'firstName', type: false},
          { prop: 'email', type: false }
        ]">
          <template v-slot:default="props">
            <ui-list-data 
              :text="`${props.item.firstName ? props.item.firstName : ''} ${props.item.lastName ? props.item.lastName : ''}`"
              :info="''">
            </ui-list-data>
            <ui-list-data
              :text="`${props.item.email}`"
              :info="''">
            </ui-list-data>
           
           <ui-list-data>
            <span v-if="props.item.lastCompleted">{{
                $d(
                    new Date(props.item.lastCompleted),
                    'short',
                    $i18n.locale === 'no' ? 'no' : 'en'
                )
              }}</span>
            <span v-else-if="props.item.completed">{{
                $d(
                    new Date(props.item.completed),
                    'short',
                    $i18n.locale === 'no' ? 'no' : 'en'
                )
              }}</span>
              <span v-else>
                -
              </span>
            </ui-list-data>
            <ui-list-data small>
              <ui-link class="ml-auto" type="danger" uppercase size="small" @click="handleOpenDeleteUserCourseModal(props.item)">{{$t('actions.delete')}}</ui-link>
            </ui-list-data>
          </template>
        </ui-list>
    </section>
    

    <!-- <department-courses v-if="department" :department="department"></department-courses> -->
    <ui-modal :show="showDeleteUserCourseModal" :text="$t('confirm.delete.user')" @confirm="handleDeleteUserCourse" @cancel="handleCloseDeleteUserCourseModal"/>
  </div>
</template>

<script>
import UiHeader from '@/components/ui/UiHeader'
import UiList from '@/components/ui/UiList'
import UiListData from '@/components/ui/UiListData'
import UiLink from "@/components/ui/UiLink";
import UiModal from "@/components/ui/UiModal";
import RichTextField from "@/components/form/RichTextField";
import { getCourse, getUserNotInCourse, assignCourse, deleteUserCourse, courseReminder } from '../api'
import UiButton from "@/components/ui/UiButton";
import { ref } from 'vue';
export default {
  data() {
    return {
      loading: false,
      courseId: null,
      departmentId: null,
      course: null,
      usersNotInCourse: [],
      progressPercentage: 0,
      progressStatus: undefined,
      selectedUsers: [],
      notCompleted: [],
      completed: [],
      lastCompleted: [],
      showDeleteUserCourseModal: false,
      markedDelete: null,
    }
  },

  components: {
    UiButton,
    UiHeader,
    UiList,
    UiListData,
    UiLink,
    RichTextField,
    UiModal
  },

  methods: {
    /**
     * Creating default sorting.
     * Setting email to lowercase to allow library component to sort it.
     * Default sorting by first name.
     * @param {*} course The course containing the users to sort
     */
    sortUsersInCourse(course) {
      if (course != null && course.users != null) {
          // Create lowercase emails to ensure correct sorting
          course.users.forEach((user) => {
            if (user.email != null) {
              user.email = user.email.toLowerCase();
            }
          });

          // Sort by first name
          course.users.sort((a, b) => {
            if (!a.firstName && !b.firstName) return 0;
            if (a.firstName && !b.firstName) return -1;
            if (!a.firstName && b.firstName) return 1;

            const nameOrder = a.firstName.toLowerCase().localeCompare(b.firstName, undefined, { 'sensitivity': 'base' });
            if (nameOrder === 0 && (a.email || b.email)) {
              // If equal sorting on first name, sort by email
              if (!a.email && !b.email) return 0;
              if (a.email && !b.email) return -1;
              if (!a.email && b.email) return 1;

              return a.email.toLowerCase().localeCompare(b.email, undefined, { 'sensitivity': 'base' });
            } else {
              return nameOrder;
            }
          });
      }
    },
    async getCourse() {
      this.loading = true
      try{
        const course = await getCourse(this.departmentId, this.courseId);
        this.sortUsersInCourse(course);
        
        const notInCourse = await getUserNotInCourse(this.departmentId, this.courseId)
        this.course = course
        if(notInCourse)
          this.usersNotInCourse = notInCourse
        if(course.users){
          this.completed = course.users.filter(u => u.completed)
          this.notCompleted = course.users.filter(u => !u.completed)
          this.setPercentageAndStatus()
        }

        this.loading = false
      }
      catch(error) {
        this.loading = false
        this.$message({ message: this.$t('errors.general'), type: 'error' })
      }
    },

    handleOpenDeleteUserCourseModal(user) {
      this.markedDelete = user;
      this.showDeleteUserCourseModal = true;
    },

    handleCloseDeleteUserCourseModal() {
      this.markedDelete = null;
      this.showDeleteUserCourseModal = false;
    },

    async handleGiveAllCourse() {
      const valid = this.courseId && this.departmentId && this.usersNotInCourse && this.usersNotInCourse.length > 0;
      if(!valid){
        this.$message({message: this.$t('errors.general'), type: 'error'})
        return
      }
      const payload = {
        courseId: this.courseId,
        departmentId: this.departmentId,
        users: this.usersNotInCourse
      }
      await this.assignCourse(payload)
    },

    async handleSave() {
      let notFound = false
      this.selectedUsers.map( su => {
        if(!this.usersNotInCourse.some(nic => nic.userId === su)){
          notFound = true
        }
      })
      const valid = this.courseId && this.departmentId && !notFound
      if(!valid){
        this.$message({ message: this.$t('errors.general'), type:'error' })
        return
      }
      const picked = this.usersNotInCourse.filter(u => {
        let match = false
        this.selectedUsers.forEach(su => {
          if(!match && su === u.userId)
            match = true
        })
        return match
      })
      const payload = {
        courseId: parseInt(this.courseId, 10),
        departmentId: parseInt(this.departmentId, 10),
        users: picked
      }
      await this.assignCourse(payload)
    },

    async assignCourse(payload) {
      this.loading = true
      try{
        const assignedUsers = await assignCourse(payload)
        assignedUsers.map(u => this.notCompleted.push(u))
        this.usersNotInCourse = this.usersNotInCourse.filter(u => {
          let found = false
          assignedUsers.forEach(a => {
            if(a.userId === u.userId)
              found = true
          })
          return !found
        })
        this.setPercentageAndStatus()
        this.selectedUsers = []
        this.loading = false
      }
      catch(error) {
        this.$message({message: this.$t('errors.general'), type: 'error'})
        this.loading = false
      }
    },

    async handleReminder(user) {
      const payload = { departmentId: this.departmentId, courseId: this.courseId, userId: user.userId }
      this.loading = true
      try{
        const res = await courseReminder(payload);
        const okMsg = { message: this.$t('feedback.task_success', { action: this.$t('actions.reminder')}), type: 'success' };
        this.$message(okMsg);
        const affected = this.notCompleted.find(u => u.userId === user.userId);
        affected.reminderSendt = res;
        this.loading = false
      }
      catch(error){
        this.$message({message: this.$t('feedback.update_failed'), type: 'error'});
        this.loading = false
      }
    },

    async handleDeleteUserCourse() {
      const user = this.markedDelete;
      if(!user){
        return;
      }
      const payload = { departmentId: this.departmentId, courseId: this.courseId, userId: user.userId }
      this.loading = true
      try {
        const courseUser = await deleteUserCourse( payload )
        this.notCompleted = this.notCompleted.filter(u => { return u.userId !== courseUser.userId })
        this.completed = this.completed.filter( u => { return u.userId !== courseUser.userId })
        this.usersNotInCourse.push(courseUser)
        this.setPercentageAndStatus()
        this.loading = false
        this.handleCloseDeleteUserCourseModal();
      }
      catch(error) {
        this.$message({ message: this.$t('errors.general'), type: 'error' })
        this.loading = false
        this.handleCloseDeleteUserCourseModal();
      }
    },

    setPercentageAndStatus() {
      const total = this.completed.length + this.notCompleted.length
      const completed = this.completed.length
      if(total > 0){
        const percentage = Math.floor( (completed / total) * 100 )
        this.progressPercentage = percentage
        if(percentage === 100)
          this.progressStatus = 'success'
        if(percentage > 0 && percentage <= 33)
          this.progressStatus = 'exception'
        if(percentage > 33 && percentage <= 66)
          this.progressStatus = 'warning'
      }
    }
  },

  created(){
    this.courseId = this.$router.history.current.params.cid
    this.departmentId = this.$router.history.current.params.id
    this.getCourse()
  },

  mounted() {
    let course = getCourse(this.$router.history.current.params.id, this.$router.history.current.params.cid)
    this.$store.commit('changeBreadcrumbs', [
      {name: "models.departments", link: "/app/departments/"},
      {name: course.name, link: ""}
    ]);
  },
}
</script>

<style>
.flex-container{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-box{
  display: flex;
  flex-direction: row;
  flex-grow: 1 0 5rem;
  justify-content: space-between;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flexed{
  width: 50%;
}
</style>